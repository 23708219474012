import React from 'react';
import ReactDOM from 'react-dom';

import { Card, CardContent, CardHeader, CardMedia, Container, CssBaseline, Typography } from '@mui/material';

import { makeStyles } from '@mui/styles';

import { createTheme, responsiveFontSizes, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import { ruRU } from '@mui/material/locale';

import similarweb from './pics/similarweb.jpg';
import snowflake from './pics/snowflake.jpg';
import facts from './pics/facts.jpg';
import optimize from './pics/optimize.jpg';
import screenshoter from './pics/screenshoter.jpg';

const theme = responsiveFontSizes(createTheme({}, ruRU), { factor: 3 });

const useClasses = makeStyles({
    header: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    rows: {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-around',
        '& > *:first-child': {
            marginRight: theme.spacing(2),
        },
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    project: {
        display: 'inline-block',
        '&:hover': {
            boxShadow: theme.shadows[7],
        },
        maxWidth: '60%',
        textDecoration: 'none',
    },
});

function ProjectCard({ title, href, background, children, ...other }) {
    const classes = useClasses();

    return (
        <Card component="a" className={classes.project} href={href} {...other}>
            <CardHeader title={title} />
            <CardMedia style={{ height: 100 }} image={background} />
            <CardContent>{children}</CardContent>
        </Card>
    );
}

const App = () => {
    const classes = useClasses();

    return (
        <ThemeProvider theme={theme}>
            <StyledEngineProvider injectFirst>
                <CssBaseline />
                <Container
                    maxWidth="md"
                    style={{
                        display: 'flex',
                        flexFlow: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Typography component="div" variant="h1" className={classes.header}>
                        <b>I.COM</b> инструменты
                    </Typography>
                    <div className={classes.rows}>
                        <ProjectCard title="Снежинка" href="https://snowflake.icom.agency" background={snowflake}>
                            Собирает статистику по ключевым словам от Яндекс и Similarweb.
                        </ProjectCard>
                        <ProjectCard title="Similarweb" href="https://similarweb.icom.agency" background={similarweb}>
                            Собирает статистику по доменам от сервиса similarweb.com.
                        </ProjectCard>
                    </div>
                    <div className={classes.rows}>
                        <ProjectCard title="Факты" href="https://facts.icom.agency" background={facts}>
                            Загрузка медиапланов и сбор статистики их эффективности.
                        </ProjectCard>
                        <ProjectCard
                            title={<span style={{ textDecoration: 'line-through' }}>Факты (устаревшее)</span>}
                            href="https://facts-old.icom.agency"
                            background={facts}
                            style={{ filter: 'grayscale(1)', opacity: 0.7 }}
                        >
                            Загрузка медиапланов и сбор статистики их эффективности. Устаревшая версия.
                        </ProjectCard>
                    </div>
                    <div className={classes.rows}>
                        <ProjectCard title="Оптимизатор" href="https://optimize.icom.agency" background={optimize}>
                            Сервис расчёта оптимального бюджета/дохода/прибыли по рекламным каналам.
                        </ProjectCard>
                        <ProjectCard title="Скриншотер" href="https://screenshot.icom.agency" background={screenshoter}>
                            Проект создания скриншотов сайтов с заменой креативов на них.
                        </ProjectCard>
                    </div>
                </Container>
            </StyledEngineProvider>
        </ThemeProvider>
    );
};

ReactDOM.render(<App />, document.getElementById('root'));
